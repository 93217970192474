<script setup lang="ts">
import type { CSSProperties } from 'vue';

const props = defineProps<{
	size?: string
	ar?: string
	circle?: boolean
	noShrink?: boolean
}>();

const skeletonStyle = computed((): CSSProperties => {
	const style: CSSProperties = {};

	// Apply size
	if (props.size) {
		const sizes = props.size.split(' ');
		style.width = sizes[0];
		style.height = sizes.length === 2 ? sizes[1] : sizes[0];
	}

	// Apply aspect ratio
	if (props.ar)
		style.aspectRatio = props.ar;

	return style;
});
</script>

<template>
	<div
		class="skeleton"
		:class="[{
			'skeleton--circle': props.circle,
			'skeleton--no-shrink': props.noShrink,
		}]"
		:style="skeletonStyle"
		aria-hidden="true"
	/>
</template>
